/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    number,
    shape,
    string,
    oneOfType,
} from 'prop-types';
import { Typography } from '@material-ui/core';
import Caret from '@material-ui/icons/ArrowForwardIos';
import LinkOrATag from '../../GraphqlComponents/GraphqlCommonComponents/LinkOrATag/LinkOrATag';
import parseFontFamilyExtension from '../../../helpers/contentstack/parseFontFamilyExtension';
import replaceSpecialChar from '../../../helpers/replaceSpecialChar';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        border: `1px solid ${(theme.palette.text?.mainSBSContainer || '#AFAFAF')}`,
        width: '100%',
        overflowY: 'hidden',
        '&:hover .videoControls': {
            transition: 'opacity .3s',
            opacity: 1,
        },
        '@media screen and (max-width: 1280.5)': {
            opacity: 1,
        },
    },
    bottomContent: {
        color: theme.palette.cms?.black || '#2F2F2F',
        padding: '0 32px 0px',
        '@media screen and (max-width: 1023px)': {
            padding: '0 22px 0px',
        },
    },
    heading: ({ styling }) => ({
        fontSize: '2rem',
        lineHeight: '40px',
        fontFamily: `${parseFontFamilyExtension(styling.title_font) || 'LatoRegular, Arial, Verdana, sans-serif'}`,
        fontWeight: 500,
        paddingTop: '16px',
        '@media screen and (max-width: 1023px)': {
            fontSize: '1.5rem',
            lineHeight: '32px',
        },
    }),
    subHeading: ({ styling }) => ({
        fontSize: '1rem',
        lineHeight: '24px',
        fontFamily: `${parseFontFamilyExtension(styling.sub_title_font) || 'LatoRegular, Arial, Verdana, sans-serif'}`,
        fontWeight: '400',
        paddingTop: '13px',
    }),
    ctaBtn: ({ styling }) => ({
        padding: '11px 40px 12px 37px',
        display: 'flex',
        fontSize: '1.25rem',
        border: `1.5px solid ${theme.palette.ctaBorderClrNup4 || '#2F2F2F'}`,
        width: 'auto',
        textAlign: 'center',
        fontFamily: `${parseFontFamilyExtension(styling.sub_title_font) || 'LatoRegular, Arial, Verdana, sans-serif'}`,
        lineHeight: '22px',
        margin: '24px 0px 32px 0px',
        alignItems: 'center',
        background: theme.palette.ctaBgColorNup4 || '#ffffff',
        color: theme.palette.ctaColorNup4 || '#2f2f2f',
        maxWidth: 'max-content',
        '@media screen and (max-width: 1366px)': {
            fontSize: '1rem',
        },
        '@media screen and (max-width: 1023px)': {
            lineHeight: '20px',
            padding: '8px 22px 9px 22px',
            margin: '20px 0px 32px 0px',
        },
    }),
    linkPanel: {
        textDecoration: 'none',
        lineHeight: '0px',
        zIndex: 0,
    },
    arrowIcon: {
        width: '0.7em',
        height: '0.7em',
        fontSize: '1.25rem',
        margin: '0px 0px 0px 5px',
    },
    threeProductLayout: {
        '& $bottomContent': {
            padding: '0 26px 28px',
            '@media screen and (max-width: 1023px) and (min-width:767px)': {
                padding: '0 11px 28px',
            },
        },
        '& $ctaBtn': {
            fontSize: '1rem',
            lineHeight: 'normal',
            margin: '13px 0px 0px 0px',
            border: 'none',
            padding: 0,
            display: 'flex',
            textAlign: 'left',
            width: '100%',
            '@media screen and (max-width: 767px) and (min-width: 740px)': {
                minHeight: '40px',
                fontSize: '0.875rem',
            },
        },
        '& $heading': {
            fontSize: '1.5rem',
            '@media screen and (max-width: 1023px)': {
                fontSize: '1.25rem',
                lineHeight: '30px',
            },
            '@media screen and (max-width: 767px) and (min-width: 740px)': {
                minHeight: '86px',

            },
        },
        '& $subHeading': {
            paddingTop: '5px',
            '@media screen and (max-width: 1023px) and (min-width: 740px)': {
                display: 'none',
            },
        },
    },
    multiProductLayout: {
        '& $bottomContent': {
            padding: '0px 19px 28px',
            '@media screen and (max-width: 1023px)': {
                padding: '0px 10px 28px',
            },
            '@media screen and (max-width: 420px)': {
                paddingBottom: '0px',
            },
        },
        '& $heading': {
            fontSize: '1.25rem',
            lineHeight: '28px',
            paddingTop: '20px',
            '@media screen and (max-width: 1023px)': {
                fontSize: '1.125rem',
                lineHeight: '26px',
            },
            '@media screen and (max-width: 840px) and (min-width: 768px)': {
                minHeight: '78px',
            },
        },
        '& $subHeading': {
            display: 'none',
        },
        '& $arrowIcon': {
            margin: '2px 0px 0px 5px',
        },
        '& $ctaBtn': {
            fontSize: '1rem',
            lineHeight: '20px',
            padding: '15px 0px 0px 0px',
            border: 'none',
            margin: '0px',
            textAlign: 'left',
            width: '100%',
            display: 'flex',
            '@media screen and (max-width: 1023px)': {
                paddingTop: '5px',
            },
            '@media screen and (max-width: 600.95px)': {
                padding: '14px 0px 23px 0px',
                letterSpacing: '0.02em',
                '& $arrowIcon': {
                    fontSize: '1.2rem',
                    margin: '3px 0px 0px 5px',
                },
            },
        },
    },
    video: {
        margin: '0 auto',
        width: '100%',
        maxHeight: '209px',
        position: 'relative',
        overflow: 'hidden',
        objectFit: 'cover',
        background: '#efefef',
        aspectRatio: 'auto 338 / 210',
        '@media screen and (max-width: 600.95px)': {
            aspectRatio: '1/1',
            maxHeight: 'fit-content',
        },
    },
    videoHasNoTitle: {
        aspectRatio: '1/1',
        maxHeight: '100%',
        '@media screen and (min-width: 739.95px) and (max-width: 1243.95px)': {
            maxHeight: '100%',
            height: '100%',
            aspectRatio: 'auto',
        },
        '@media screen and (max-width: 600.95px)': {
            maxHeight: '100%',
            height: '100%',
            aspectRatio: 'auto',
        },
    },
    videoObject: {
        height: '100%',
        objectFit: 'cover',
    },
    videoControls: {
        position: 'absolute',
        right: '10px',
        bottom: '10px',
        zIndex: '1',
        opacity: 0,
        '@media screen and (max-width: 1024px)': {
            opacity: 1,
        },
        display: 'flex',
        '& div': {
            cursor: 'pointer',
        },
    },
    videoHasTitle: {
        top: '10px',
    },
    videoControlPlay: {
        marginRight: '.8em',
        width: 0,
        height: 0,
        borderWidth: '10px 0 10px 12px',
        borderColor: 'transparent transparent transparent #ffffff55',
        borderStyle: 'solid',
        '&:hover': {
            borderColor: 'transparent transparent transparent #ffffff',
        },
        '@media screen and (max-width: 1024px)': {
            borderWidth: '5px 0 5px 6px',
        },
    },
    videoControlPause: {
        border: 'solid 5px #ffffff55',
        borderWidth: '0 6px',
        height: '20px',
        width: '16px',
        '&:hover': {
            borderColor: '#ffffff',
        },
        '@media screen and (max-width: 1024px)': {
            height: '10px',
            width: '8px',
            borderWidth: '0 3px',
        },
    },
    videoControlVolume: {
        marginLeft: '.8em',
        height: '20px',
        width: '20px',
        overflow: 'hidden',
        backgroundImage: 'url(https://images.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt694c8076329d539a/volumesprite.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        opacity: '0.5',
        '@media screen and (max-width: 1024px)': {
            height: '10px',
            width: '10px',
        },
    },
    videoControlVolumeUnmute: {
        backgroundPositionX: '0',
    },
    videoControlVolumeMute: {
        backgroundPositionX: '100%',
    },
}));

const NupV4Panel = ({
    panelItem, styling, productLength,
}) => {
    const classes = useStyles({
        styling,
    });
    const loadCssProductBase = () => {
        switch (productLength) {
            case 2:
                return classes.twoProductLayout;
            case 3:
                return classes.threeProductLayout;
            default:
                return classes.multiProductLayout;
        }
    };

    const paneltitle = panelItem?.linking?.link;
    // const isMobile = useMediaQuery('(max-width: 600px)');
    const [vol, setVol] = useState(true);
    const videoRef = useRef(null);
    const playVideo = () => videoRef.current.play();
    const pauseVideo = () => videoRef.current.pause();
    // eslint-disable-next-line no-return-assign
    const volumeVideo = () => setVol(!vol);
    const video = panelItem.video;
    const image = {};
    const createVideoID = () => {
        const src = video?.mp4?.url || image?.url || 'Video';
        /**
         * turn https://assets.contentstack.io/v3/assets/blt89dbf1c763ec00a6/blt39521815bb9e56cb/6511b146474d4d76f24f2c43/23_025_001_HD_PearTabletop-A15_1400x584_G0_M0_A1.mp4
         * into 23_025_001_HD_PearTabletop-A15_1400x584_G0_M0_A1
         * */
        const id = src.split('/')?.pop()?.split('.')[0] || 'ComponentVideo';
        return id;
    };
    return (
        <div className={classes.container}>
            <div className={`${classes.videoControls} ${panelItem?.title && panelItem?.sub_title ? classes.videoHasTitle : ''} videoControls`}>
                <div className={classes.videoControlPlay} onClick={playVideo} onKeyDown={playVideo} role="button" tabIndex="0" aria-controls={`video_${createVideoID()}`} aria-pressed="false" aria-label="Play Video" />
                <div className={classes.videoControlPause} onClick={pauseVideo} onKeyDown={pauseVideo} role="button" tabIndex="0" aria-controls={`video_${createVideoID()}`} aria-pressed="false" aria-label="Pause Video" />
                {video?.has_audio && <div className={`${classes.videoControlVolume} ${!vol ? classes.videoControlVolumeUnmute : classes.videoControlVolumeMute}`} onClick={volumeVideo} onKeyDown={volumeVideo} role="button" tabIndex="0" aria-pressed="false" aria-label="Mute/Unmute Video" />}
            </div>
            <LinkOrATag
                className={`${classes.linkPanel} ${loadCssProductBase()}`}
                href={paneltitle?.href}
                isTrackEventEnabled
                trackingEventAction={panelItem?.linking?.tracking_event_action}
                trackingEventCategory={panelItem?.linking?.tracking_event_category}
                trackingEventLabel={panelItem?.linking?.tracking_event_label}
            >

                <div className={`${classes.video}  ${!panelItem?.title?.length ? classes.videoHasNoTitle : ''}`}>
                    <video ref={videoRef} id={`video_${createVideoID()}`} data-testid="video-box" autoPlay playsInline loop muted={vol} width="100%" className={classes.videoObject}>
                        <source src={video?.webm?.url || image?.url} type={video?.webm?.content_type || image?.content_type} preload="metadata" />
                        <source src={video?.mp4?.url || image?.url} type={video?.mp4?.content_type || image?.content_type} preload="metadata" />
                        <track kind="captions" />
                    </video>
                </div>
                {(panelItem?.title && panelItem?.sub_title) && (
                    <div className={classes.bottomContent}>
                        {panelItem?.title && (<Typography className={`${classes.heading} nup-v4-panel-item`}> {replaceSpecialChar(panelItem.title, true)} </Typography>) }
                        {panelItem?.sub_title && (<Typography className={`${classes.subHeading} nup-v4-panel-item`}> {panelItem.sub_title} </Typography>) }
                        {paneltitle?.title && (<Typography className={`${classes.ctaBtn} nup-v4-panel-item`}> {paneltitle.title}{<Caret className={classes.arrowIcon} />}</Typography>)}
                    </div>
                )}
            </LinkOrATag>
        </div>
    );
};

NupV4Panel.propTypes = {
    panelItem: shape({
        title: string,
        image: shape({
            url: string,
            content_type: string,
        }),
    }),
    styling: shape({
        presentation_style: string,
        title_font_color: shape({
            color: string,
        }),
    }),
    productLength: oneOfType([number, string]),
};
NupV4Panel.defaultProps = {
    panelItem: {},
    styling: {},
    productLength: '',
};

export default NupV4Panel;
